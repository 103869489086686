declare global {
  interface Window {
    gtag: (
      option: string,
      gaTrackingId: string,
      options: Record<string, unknown>,
    ) => void;
  }
}

/**
 * @example
 * https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 */
const pageview = (url: string, trackingId: string) => {
  if (!window.gtag) {
    console.warn(
      'window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet.',
    );
    return;
  }
  window.gtag('config', trackingId, {
    page_path: url,
  });
};

/**
 * @example
 * https://developers.google.com/analytics/devguides/collection/gtagjs/events
 */
const event = ({
  action,
  category,
  label,
  value,
  tracking = 'trackSingle',
}: {
  action: string;
  category: string;
  label: string;
  value: number;
  tracking?: 'track' | 'trackSingle';
}) => {
  if (!window.gtag) {
    console.warn(
      'window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet.',
    );
  } else {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      currency: 'USD',
      value: value,
    });
  }

  if (!window.fbq) {
    console.log('no FBQ');
  } else {
    window.fbq(tracking, '176376254245910', action, { currency: 'USD', value });
  }
};

export const gtag = {
  event,
  pageview,
};
